import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextFormInput from "./textFormInput";
import P1 from "./styled/P1";
import CardWrapper from "./cards/CardWrapper";

const fontStack =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif";

const PreviewTitle = styled.a`
  display: block;
  word-break: break-word;
  font-family: ${fontStack};
  font-style: normal;
  font-weight: medium;
  line-height: 26px;
  font-size: 17px;
  color: #4f4b65;
  text-decoration-color: #d1d0d5;
`;

const PreviewDetails = styled.span`
  display: block;
  font-family: ${fontStack};
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 13px;
  color: #bbbac1;
  margin-top: 4px;
  margin-bottom: 12px;
`;

const PreviewDescription = styled.span`
  font-family: ${fontStack};
  font-style: normal;
  line-height: 22px;
  display: block;
  word-break: break-word;
  margin-top: -8px;
  font-size: 14px;
  color: #838094;
`;

const PreviewImage = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 6px;
  object-fit: cover;
`;

const LinkPreviewWrapper = styled.div`
  margin: 2.5em auto 2.5em;
  max-width: 600px;
  zoom: 1.1;
  @media (max-width: 640px) {
    zoom: 1;
  }
`;

const FilePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AdInteractivePreview = () => {
  const [title, setTitle] = useState("");
  const [domain, setDomain] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const titlePlaceholder = "Your Product Name";
  const domainPlaceholder = "example.com";
  const descriptionPlaceholder =
    "Description of your product. It can be a long paragraph describing the main features of your product.";

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }
  function handleDomainChange(event) {
    setDomain(event.target.value);
  }
  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  function previewFile(event) {
    var file = event.target.files[0];
    var reader = new FileReader();

    reader.onloadend = function() {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImage("");
    }
  }

  return (
    <CardWrapper>
      <TextFormInput nomargin value={title} placeholder={titlePlaceholder} onChange={handleTitleChange} />
      <TextFormInput nomargin value={domain} placeholder={domainPlaceholder} onChange={handleDomainChange} />
      <TextFormInput
        nomargin
        value={description}
        placeholder={descriptionPlaceholder}
        onChange={handleDescriptionChange}
        big
      />
      <FilePickerWrapper>
        <P1>Select an image to use as thumbnail:</P1>
        <input id="filepicker" type="file" onChange={previewFile} />
      </FilePickerWrapper>
      <br />
      <LinkPreviewWrapper>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <PreviewTitle>{title || titlePlaceholder}</PreviewTitle>
                <PreviewDetails>
                  sponsored{" "}
                  <img
                    width="12px"
                    style={{ marginBottom: "-1px", marginRight: "2px", marginLeft: "6px" }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHVSURBVHgB7VTBccIwELxz4GUelAAdQAVYM+AZXoEOoILQAaSCJBVAKiA8MgyQGZkKoASXwCPhg8XlJDBjgwM4+SXZj22dvHd7exLAP349EFJCDmU+yFl3CNQhgjwvLQlw4LqVpx8nkFLm1QYXBFBICC9rrlM+XrQgJbbMzpVz1VRmQgRFgr99DpUmE9k73p+6RUmYjaUDNyiZzK+6TjEaS60gCbW68PQzqXWZcz8eDCVq7Ql8tHBQrVaeo/umr7Jk4rtWxfClAjmWBWXjgv/q6cr21Tm0pcFsNu9Gi8As9g0ZgndVAkPOPTXEbKg2MlBUZGPb7NqKkzoHhTZKfi3p6jeK7o+58BL5x5qEHvYgB6VaTXhDJtX7zFqEXG1J1OvCP6sgibzZFKuNDT0glG9Tr5/jRLYNjWvINWImBwgN2JH7IbkJKG5TBla83uJErVD2JfITBezSrembRe0DOcOti0EQUJkN1teBxwpfELDDRZTPkZ8owN20QBCcjtueqAMpEVOgiOZm0eJWRKCnZcz+wDcQm6LwyO8i2CPajhB5YAgfQBvK94/riiWkQEyBOfK0n2U+YNznhZ4eCKdFwQpSIvGyM0c/i10M7xai0fsaHqPG/x18ArNyAcTN0ORKAAAAAElFTkSuQmCC"
                  />{" "}
                  {domain || domainPlaceholder}
                </PreviewDetails>
                <PreviewDescription>{description || descriptionPlaceholder}</PreviewDescription>
              </td>
              {image && (
                <td width="54px" style={{ paddingLeft: "20px" }}>
                  <PreviewImage id="preview" src={image} />
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </LinkPreviewWrapper>
    </CardWrapper>
  );
};

export default AdInteractivePreview;
