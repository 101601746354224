import React from "react";
import Helmet from "react-helmet";
import AdInteractivePreview from "../components/adInteractivePreview";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";

if (typeof window !== "undefined") {
  // Make scroll behavior of internal links smooth
  require("../../utils/smooth-scroll.polyfills.min.js")('a[href*="#"]');
}

const AdPreviewPage = () => {
  return (
    <Layout darkbg>
      <SEO title="Ad Preview" />
      <Helmet meta={[{ name: "robots", content: "noindex" }]} />
      <Section
        title="Preview your Ad"
        description="Enter the details of the ad and preview how it will look on our newsletters"
        darkbg
      >
        <AdInteractivePreview />
      </Section>
    </Layout>
  );
};

export default AdPreviewPage;
