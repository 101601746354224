import React from "react";
import styled from "styled-components";
import TextInput from "./styled/TextInput";
import Button from "./button";

const FormWrapper = styled.div`
  ${props =>
    props.label &&
    `
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1em;
  `};
  * {
    text-align: left;
  }
  margin-top: ${props => (props.nomargin ? "0em" : "0.8em")};
  margin-bottom: 1em;
`;

const FormError = styled.div`
  :not(:empty) {
    color: ${props => (props.warning ? "#ff5959" : "#43bc56")};
    margin-bottom: 0.8em;
  }
`;

const TextFormInput = props => {
  const { value, placeholder, label, onClick, onChange, errorMessage, big, nomargin } = props;

  // setFormMessage({text: "You need to insert a valid email.", warning: true});

  return (
    <div>
      <FormWrapper label={label} nomargin={nomargin}>
        <TextInput
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyPress={e => {
            if (e.key === "Enter") {
              onClick();
            }
          }}
          big={big}
          style={{ marginTop: 0, marginBottom: 0 }}
        />
        {label && <Button label={label} onClick={onClick} style={{ width: "6em" }} />}
      </FormWrapper>
      {errorMessage && <FormError warning={errorMessage.warning}>{errorMessage.text}</FormError>}
    </div>
  );
};

export default TextFormInput;
